import { Howl } from 'howler';
import {
  PESeverities,
  PE_HAZARDOUS,
  PE_MAJOR,
  PE_MINIMAL,
  PE_MINOR,
} from './constants';

const peHazardous = new Howl({
  src: PE_HAZARDOUS,
  preload: true,
});

const peMajor = new Howl({
  src: PE_MAJOR,
  preload: true,
});

const peMinimal = new Howl({
  src: PE_MINIMAL,
  preload: true,
});

const peMinor = new Howl({
  src: PE_MINOR,
  preload: true,
});

type PriorityEventsAlertsProps = {
  severities: PESeverities[];
};

const PriorityEventsAlerts = ({
  severities,
}: PriorityEventsAlertsProps): null => {
  if (severities.includes('HAZARDOUS')) {
    if (!peHazardous.playing()) {
      peHazardous.play();
      peMajor.stop();
      peMinor.stop();
      peMinimal.stop();
    }
  } else if (severities.includes('MAJOR')) {
    if (!peMajor.playing()) {
      peMajor.play();
      peMinor.stop();
      peMinimal.stop();
    }
  } else if (severities.includes('MINOR')) {
    if (!peMinor.playing()) {
      peMinor.play();
      peMinimal.stop();
    }
  } else if (severities.includes('MINIMAL')) {
    if (!peMinimal.playing()) {
      peMinimal.play();
    }
  }
  return null;
};

export default PriorityEventsAlerts;
