import { Box, Icon } from '@chakra-ui/react';
import { faCircle, faEye } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

type ActiveFlightIconProps = {
  isActive: boolean;
};

const ActiveFlightIcon = ({ isActive }: ActiveFlightIconProps) => (
  <Box
    as="span"
    className="fa-stack"
    visibility={isActive ? 'initial' : 'hidden'}
  >
    <Icon
      as={FontAwesomeIcon}
      icon={faCircle}
      className="fa-stack-2x"
      color="gray.100"
    />
    <Icon
      as={FontAwesomeIcon}
      icon={faEye}
      className="fa-stack-1x"
      color="blue.900"
    />
  </Box>
);

export default ActiveFlightIcon;
