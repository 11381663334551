import React from 'react';

// TODO: Try to utilize SVGR and load instead; there were problems with storybook showing them

export const HazardousIcon = () => (
  <svg
    width="81"
    height="52"
    viewBox="0 0 81 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Property 1=Hazardous">
      <rect width="81" height="52" fill="#F1C5C6" />
      <g id="Group 122">
        <path id="Rectangle 9" d="M18 0H75V52H18V0Z" fill="white" />
        <path
          id="exclamation-square"
          d="M63.25 8.5H35.75C33.6406 8.5 32 10.2188 32 12.25V39.75C32 41.8594 33.6406 43.5 35.75 43.5H63.25C65.2812 43.5 67 41.8594 67 39.75V12.25C67 10.2188 65.2812 8.5 63.25 8.5ZM47 16H51.9219C52.4688 16 52.9375 16.4688 52.8594 17.0156L52.3125 27.6406C52.2344 28.1875 51.8438 28.5 51.375 28.5H47.5469C47.0781 28.5 46.6875 28.1875 46.6094 27.6406L46.0625 17.0156C45.9844 16.4688 46.4531 16 47 16ZM49.5 37.0938C47.4688 37.0938 45.9062 35.5312 45.9062 33.5C45.9062 31.5469 47.4688 29.9062 49.5 29.9062C51.4531 29.9062 53.0938 31.5469 53.0938 33.5C53.0938 35.5312 51.4531 37.0938 49.5 37.0938Z"
          fill="#CF3034"
        />
      </g>
      <rect id="Rectangle 39" x="75" width="6" height="52" fill="white" />
    </g>
  </svg>
);

export const MajorIcon = () => (
  <svg
    width="81"
    height="52"
    viewBox="0 0 81 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Property 1=Major">
      <rect width="81" height="52" fill="#FEF3C7" />
      <path id="Rectangle 10" d="M40 0H81V52H8L40 0Z" fill="white" />
      <path
        id="exclamation-triangle"
        d="M70.8653 41.4531L52.1153 8.875C50.709 6.45312 47.0372 6.375 45.6309 8.875L26.8809 41.4531C25.4747 43.875 27.2715 47 30.1622 47H67.584C70.4747 47 72.2715 43.9531 70.8653 41.4531ZM48.9122 34.6562C50.8653 34.6562 52.5059 36.2969 52.5059 38.25C52.5059 40.2812 50.8653 41.8438 48.9122 41.8438C46.8809 41.8438 45.3184 40.2812 45.3184 38.25C45.3184 36.2969 46.8809 34.6562 48.9122 34.6562ZM45.4747 21.7656C45.3965 21.2188 45.8653 20.75 46.4122 20.75H51.334C51.8809 20.75 52.3497 21.2188 52.2715 21.7656L51.7247 32.3906C51.6465 32.9375 51.2559 33.25 50.7872 33.25H46.959C46.4903 33.25 46.0997 32.9375 46.0215 32.3906L45.4747 21.7656Z"
        fill="#FBBF24"
      />
    </g>
  </svg>
);

export const MinorIcon = () => (
  <svg
    width="81"
    height="52"
    viewBox="0 0 81 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Property 1=Minor">
      <rect
        width="81"
        height="52"
        transform="matrix(-1 0 0 1 81 0)"
        fill="#DBE7F5"
      />
      <g id="Group 121">
        <path
          id="Intersect"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29.5688 52C22.4652 45.5942 18 36.3185 18 26C18 15.6815 22.4652 6.40584 29.5688 0H76V52H29.5688Z"
          fill="white"
        />
        <path
          id="exclamation-circle"
          d="M71.375 26C71.375 15.375 62.625 6.625 52 6.625C41.2969 6.625 32.625 15.375 32.625 26C32.625 36.7031 41.2969 45.375 52 45.375C62.625 45.375 71.375 36.7031 71.375 26ZM52 29.9062C53.9531 29.9062 55.5938 31.5469 55.5938 33.5C55.5938 35.5312 53.9531 37.0938 52 37.0938C49.9688 37.0938 48.4062 35.5312 48.4062 33.5C48.4062 31.5469 49.9688 29.9062 52 29.9062ZM48.5625 17.0156C48.4844 16.4688 48.9531 16 49.5 16H54.4219C54.9688 16 55.4375 16.4688 55.3594 17.0156L54.8125 27.6406C54.7344 28.1875 54.3438 28.5 53.875 28.5H50.0469C49.5781 28.5 49.1875 28.1875 49.1094 27.6406L48.5625 17.0156Z"
          fill="#5B80B3"
        />
      </g>
      <rect id="Rectangle 41" x="76" width="5" height="52" fill="white" />
    </g>
  </svg>
);
