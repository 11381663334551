/** This configures the number of "slots" shown in UI */
export const PRIORITY_EVENTS_LIMIT = 3;
export const PE_HAZARDOUS = '/sounds/mc_s4_hazardous_010322.mp3';
export const PE_MAJOR = '/sounds/mc_s3_major_010322.mp3';
export const PE_MINOR = '/sounds/mc_s2_minor_010322.mp3';
export const PE_MINIMAL = '/sounds/mc_s1_minimal_010322.ogg';
// List of existing PE severities (in order of urgency)
export type PESeverities = 'HAZARDOUS' | 'MAJOR' | 'MINOR' | 'MINIMAL';
export const PE_SEVERITIES: PESeverities[] = [
  'HAZARDOUS',
  'MAJOR',
  'MINOR',
  'MINIMAL',
];
