import React, { useState } from 'react';
import { Grid } from '@chakra-ui/react';
import { Card, CardContent } from '@bit/matternet.shared.components.card';
import { usePrevious } from 'react-use';
import { AnimateSharedLayout, AnimatePresence } from 'framer-motion';
import { PriorityEvent, PriorityEventsProps } from './types';
import { getNewEventsSeverities } from '../../utils/priorityEventSounds';
import PriorityEventCard, {
  PriorityEventPlaceholder,
} from './PriorityEventCard';
import { PRIORITY_EVENTS_LIMIT } from './constants';
import AnnunciatorTestHeader from '../shared/AnnunciatorTestHeader';
import PriorityEventsAlerts from './PriorityEventsAlerts';

const PriorityEvents = ({ data, onClick }: PriorityEventsProps) => {
  const [useTest, setTestData] = useState(false);

  if (data.length > 3) {
    throw Error(
      'PriorityEvents only supports up to 3 items at this time. Try reducing the number of elements in data.',
    );
  }

  let dataWithTestEvent = [...data];

  if (useTest) {
    const testVehicleData: PriorityEvent = {
      id: 0,
      vehicleName: 'TEST-VEHICLE',
      eventMessage: 'Test Priority Event',
      vehicleId: 0,
      flightPlanId: 0,
      eventType: 'HOLDING',
      batteryPercentage: 52,
      isUserCommanded: true,
      severity: 'HAZARDOUS',
    };
    dataWithTestEvent = [testVehicleData, ...data].slice(0, 3);
  }

  for (let i = 0; i < PRIORITY_EVENTS_LIMIT; i += 1) {
    if (!dataWithTestEvent[i]) {
      dataWithTestEvent.push(null);
    }
  }

  const prevData = usePrevious(dataWithTestEvent);

  const heading = (
    <AnnunciatorTestHeader
      headerText="Priority Events"
      onSetTestState={setTestData}
    />
  );

  return (
    <Card heading={heading}>
      <CardContent>
        <Grid
          overflow="hidden"
          gridTemplateRows={{
            base: '1fr',
            md: '1fr',
          }}
          gridTemplateColumns={{
            base: '1fr 1fr 1fr',
            md: '1fr 1fr 1fr',
          }}
          gridTemplateAreas={{
            base: `"pe-slot-1 pe-slot-2 pe-slot-3"`,
            md: `"pe-slot-1 pe-slot-2 pe-slot-3"`,
          }}
          gap={6}
        >
          <PriorityEventsAlerts
            severities={getNewEventsSeverities(dataWithTestEvent, prevData)}
          />
          <AnimateSharedLayout type="crossfade">
            <AnimatePresence initial={false}>
              {dataWithTestEvent.map((priorityEvent, index) => {
                const number = index + 1;

                if (priorityEvent === null) {
                  return null;
                }

                return (
                  <PriorityEventCard
                    id={priorityEvent.id}
                    vehicleId={priorityEvent.vehicleId}
                    key={priorityEvent.id}
                    number={number}
                    onPriorityEventCardClick={() => {
                      onClick(priorityEvent.flightPlanId);
                    }}
                    flightPlanId={priorityEvent.flightPlanId}
                    vehicleName={priorityEvent.vehicleName}
                    eventType={priorityEvent.eventType}
                    eventMessage={priorityEvent.eventMessage}
                    batteryPercentage={priorityEvent.batteryPercentage}
                    isUserCommanded={priorityEvent.isUserCommanded}
                    severity={priorityEvent.severity}
                  />
                );
              })}
            </AnimatePresence>
          </AnimateSharedLayout>
          <PriorityEventPlaceholder key={1} number={1} />
          <PriorityEventPlaceholder key={2} number={2} />
          <PriorityEventPlaceholder key={3} number={3} />
        </Grid>
      </CardContent>
    </Card>
  );
};

export default PriorityEvents;
