import { Box, Tag, TagLabel } from '@chakra-ui/react';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKeyboard } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'next-i18next';

export type FlightStateTagProps = {
  flightStatusText?: string;
  isUserCommanded?: boolean;
};

const FlightStateTag = ({
  flightStatusText,
  isUserCommanded,
}: FlightStateTagProps) => {
  const { t } = useTranslation('flight-states');
  return (
    <Tag colorScheme="blue" rounded="full" size="sm" px={4}>
      {isUserCommanded ? (
        <Box as={FontAwesomeIcon} icon={faKeyboard} mr={2} />
      ) : null}
      <TagLabel fontSize="xs" fontFamily="mono">
        {t(flightStatusText || ' - ')}
      </TagLabel>
    </Tag>
  );
};

export default FlightStateTag;
