import React from 'react';
import { Flex, Button } from '@chakra-ui/react';

type AnnunciatorTestHeaderProps = {
  headerText: string;
  onSetTestState: (state: boolean) => void;
};

const AnnunciatorTestHeader = ({
  headerText,
  onSetTestState,
}: AnnunciatorTestHeaderProps) => {
  const turnOn = () => {
    onSetTestState(true);
  };

  const turnOff = () => {
    onSetTestState(false);
  };

  return (
    <Flex justifyContent="space-between" alignItems="center">
      {headerText}
      <Button
        colorScheme="gray"
        letterSpacing={1.25}
        color="gray.300"
        textTransform="uppercase"
        size="xs"
        variant="ghost"
        onMouseDownCapture={turnOn}
        onMouseUpCapture={turnOff}
        onMouseOutCapture={turnOff}
      >
        Test
      </Button>
    </Flex>
  );
};

export default AnnunciatorTestHeader;
