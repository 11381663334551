import { uniq, isString } from 'lodash';
import { PESeverities } from '../components/PriorityEvents/constants';
import { PriorityEventsData } from '../components/PriorityEvents/types';

export const hasNewEvent = (
  data: PriorityEventsData,
  prevData?: PriorityEventsData,
): boolean => {
  const count = data.length;
  const prevCount = prevData?.length || 0;
  const isNewEvent = count > 0 && prevCount && count > prevCount;
  const isDataChanged = data.some(
    (event) => !prevData?.some((prevEvent) => event?.id === prevEvent?.id),
  );

  return isNewEvent || isDataChanged;
};

export const getNewEventsSeverities = (
  data: PriorityEventsData,
  prevData?: PriorityEventsData,
): PESeverities[] => {
  const newEvents = data
    .filter(
      (event) => !prevData?.some((prevEvent) => event?.id === prevEvent?.id),
    )
    .map((event) => event?.severity)
    .filter(isString) as PESeverities[];

  const newEventsSeverities = uniq(newEvents);

  return newEventsSeverities;
};
